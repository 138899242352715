import api from './request';

export function getUserEmployeeInfo() {
  return api.postV2<ApiOrg.GetUserEmployeeInfoRespData>({
    // url: '/digitalize/mid/organization/center/reader/getUserEmployeeInfo',
    url: '/digitalize/mid/organization/admin/getOrgEmployee',
    data: {}
  });
}

// https://b9nhtlfpd3.feishu.cn/wiki/ZOKJwBNMLifS7ikaWvRcwW9MnMh

// 创建组织
export function createOrg(param: ApiOrg.CreateOrgReqParam) {
  return api.postV2<ApiOrg.CreateOrgRespData>({
    url: '/digitalize/mid/organization/admin/createOrg',
    data: param
  });
}

// 查询组织列表
export function getOrgList(param: ApiOrg.OrgListReqParam) {
  return api.postV2<ApiOrg.OrgListRespData>({
    url: '/digitalize/mid/organization/admin/getOrgList',
    data: param
  });
}
// 编辑组织
export function updateOrg(param: ApiOrg.UpdateOrgReqParam) {
  return api.postV2<ApiOrg.UpdateOrgRespData>({
    url: '/digitalize/mid/organization/admin/updateOrg',
    data: param
  });
}

// 查询组织信息
// path: digitalize/mid/organization/admin/getOrg

// 获取组织图例
export function getOrgTree(params: ApiOrg.GetOrgTreeReqParam) {
  return api.postV2<ApiOrg.GetOrgTreeRespData>({
    url: '/digitalize/mid/organization/admin/getRootOrgTree',
    data: params
  });
}

export function getOrg(param: ApiOrg.GetOrgReqParam) {
  return api.postV2<ApiOrg.GetOrgRespData>({
    url: '/digitalize/mid/organization/admin/getOrg',
    data: param
  });
}

// 获取部门类型
export function getNodeTypeList() {
  return api.postV2<ApiOrg.GetNodeTypeListRespData>({
    url: '/digitalize/mid/organization/admin/getNodeTypeList',
    data: {}
  });
}

// 新建部门
export function addOrgNode(param: ApiOrg.AddOrUpdateOrgNodeReqParam) {
  return api.postV2<ApiOrg.AddOrUpdateNodeRespData>({
    url: '/digitalize/mid/organization/admin/addOrgNode',
    data: param
  });
}

// 修改部门信息
export function updateOrgNode(param: ApiOrg.AddOrUpdateOrgNodeReqParam) {
  return api.postV2<ApiOrg.AddOrUpdateNodeRespData>({
    url: '/digitalize/mid/organization/admin/updateOrgNode',
    data: param
  });
}

//
export function batchGetOrgNodeInfo(param: ApiOrg.BatchGetOrgNodeInfoReqParam) {
  return api.postV2<ApiOrg.BatchGetOrgNodeInfoRespData>({
    url: '/digitalize/mid/organization/admin/batchGetOrgNodeInfo',
    data: param
  });
}

// 查询下级部门信息 https://b9nhtlfpd3.feishu.cn/wiki/ZOKJwBNMLifS7ikaWvRcwW9MnMh
export function getOrgNodeLeafList(param: ApiOrg.GetOrgNodeLeafListReqParam) {
  return api.postV2<ApiOrg.GetOrgNodeLeafListRespData>({
    url: '/digitalize/mid/organization/admin/getOrgNodeChildren',
    data: param
  });
}

// 新建岗位
export function addPosition(param: ApiOrg.AddOrUpdatePositionReqParam) {
  return api.postV2<ApiOrg.AddOrUpdatePositionRespData>({
    url: '/digitalize/mid/organization/admin/addPositionType',
    data: param
  });
}

export function updatePosition(param: ApiOrg.AddOrUpdatePositionReqParam) {
  return api.postV2<ApiOrg.AddOrUpdatePositionRespData>({
    url: '/digitalize/mid/organization/admin/updatePositionType',
    data: param
  });
}

// 获取岗位列表
export function getPosition(param: ApiOrg.GetPositionReqParam) {
  return api.postV2<ApiOrg.GetPositionRespData>({
    url: '/digitalize/mid/organization/admin/getPositionType',
    data: param
  });
}

// 获取岗位列表
export function getOrgPosition(param: ApiOrg.GetOrgPositionReqParam) {
  return api.postV2<ApiOrg.GetOrgPositionRespData>({
    url: '/digitalize/mid/organization/admin/getOrgPosition',
    data: param
  });
}

// 删除关联岗位
export function deleteOrgPosition(param: ApiOrg.DeleteOrgPositionReqParam) {
  return api.postV2<ApiOrg.DeleteOrgPositionRespData>({
    url: '/digitalize/mid/organization/admin/deleteOrgPosition',
    data: param
  });
}

// 添加关联岗位
export function addOrgPosition(param: ApiOrg.AddOrgPositionReqParam) {
  return api.postV2<ApiOrg.AddOrgPositionRespData>({
    url: '/digitalize/mid/organization/admin/addOrgPosition',
    data: param
  });
}

// 获取员工列表
export function getOrgEmployeeList(param: ApiOrg.GetOrgEmployeeListReqParam) {
  return api.postV2<ApiOrg.GetOrgEmployeeListRespData>({
    url: '/digitalize/mid/organization/admin/getOrgEmployeeList',
    data: param
  });
}

// 查询员工信息
export function getOrgEmployee(param: ApiOrg.GetOrgEmployeeReqParam) {
  return api.postV2<ApiOrg.GetOrgEmployeeRespData>({
    url: '/digitalize/mid/organization/admin/getOrgEmployee',
    data: param
  });
}

// 新增员工
export function addOrgEmployee(param: ApiOrg.AddOrgEmployeeReqParam) {
  return api.postV2<ApiOrg.AddOrgEmployeeRespData>({
    url: '/digitalize/mid/organization/admin/addOrgEmployee',
    data: param
  });
}

// 编辑员工
export function updateOrgEmployee(param: ApiOrg.UpdateOrgEmployeeReqParam) {
  return api.postV2<ApiOrg.UpdateOrgEmployeeRespData>({
    url: '/digitalize/mid/organization/admin/updateOrgEmployee',
    data: param
  });
}

// 飞书邮箱查询用户信息
export function getUserInfoByEmail(param: ApiOrg.GetUserInfoByEmailReqParam) {
  return api.postV2<ApiOrg.GetUserInfoByEmailRespData>({
    url: '/digitalize/mid/organization/admin/getUserInfo',
    data: param
  });
}

// 导入员工
export function importEmployee(param: ApiOrg.ImportEmployeeReqParam) {
  return api.postV2<ApiOrg.ImportEmployeeRespData>({
    url: '/digitalize/mid/organization/admin/importEmployee',
    data: param
  });
}

// 当前部门导航
export function getOrgNodeRoute(param: ApiOrg.GetOrgNodeRouteReqParam) {
  return api.postV2<ApiOrg.GetOrgNodeRouteRespData>({
    url: '/digitalize/mid/organization/admin/getOrgNodeRoute',
    data: param
  });
}

// 查询修改日志
export function getLog(param: ApiOrg.GetLogReqParam) {
  return api.postV2<ApiOrg.GetLogRespData>({
    url: '/digitalize/mid/organization/admin/getLog',
    data: param
  });
}
