// import { AxiosPromise } from 'axios';
import api from './request';
// import { env } from './config';

// https://testapi.nr.lingdong.cn/gw/api/user-admin/loginBUserDetail
export function loginBUserDetail() {
  return api.post<ApiCommon.LoginUserDetailRespData>('user-admin/loginBUserDetail', {});
}

// 获取用户拥有的Action资源列表
// export const getAuthResourceList: <T>() => AxiosPromise<T> = () =>
//   api.request({
//     url: '/v1/web/resource/getAuthResourceList',
//     baseURL: env.apiBase,
//     method: 'GET',
//     params: {
//       projectTag: 'demo'
//     }
//   });

// 获取用户拥有的左侧导航权限列表
// export const getMenuList: <T>() => AxiosPromise<T> = () =>
//   api.request({
//     url: '/v1/web/resource/getAuthMenu',
//     baseURL: env.apiBase,
//     method: 'GET',
//     params: {
//       projectTag: 'demo'
//     }
//   });

// 加载权限
export function getUserProjectResource(requestData: ApiCommon.AuthorityReqParam) {
  return api.post<ApiCommon.AuthorityRespData>(
    '/retail/access/control/getUserProjectResource',
    requestData,
    {
      type: 'retail'
    }
  );
}

// 上传文件
export const uploadFile = (requestData: FormData) =>
  api.post<string>('/uploadFilePublic', requestData, {
    type: 'upload'
  });

// 上传文件
export const uploadFileAPI = (requestData: FormData) =>
  api.post<string>(
    '/oss/file/uploadFilePublic',
    requestData,
    {
      type: 'upload'
    },
    { timeout: 30 * 1000 }
  );

// 获取OSS签名接口
export function getOssToken(requestData: ApiCommon.ApiGetOssTokenReqParam) {
  // https://testapi.nr.lingdong.cn/oss/file/getResourceSign
  return api.post<ApiCommon.ApiGetOssTokenRespData>('/oss/file/getResourceSign', requestData, {
    type: 'upload'
  });
}

interface OssReqParam {
  url: string;
  header?: { string: string };
  formData: object;
}
// 获取OSS签名接口
export function uploadOss(param: OssReqParam) {
  // https://testapi.nr.lingdong.cn/oss/file/getResourceSign
  return api.uploadOss({
    url: param.url,
    header: param.header,
    formData: param.formData ?? {}
  });
}
// 获取OSS签名接口
export function ossPreviewLink(param: ApiCommon.GetOssPreviewReqParam) {
  // https://testapi.nr.lingdong.cn/oss/file/getResourceSign
  if ((param.downloadExpireTime ?? 0) === 0) {
    param.downloadExpireTime = 5 * 60;
  }
  return api.post<string>('/oss/file/getUrlByFileKey', param, {
    type: 'upload'
  });
}

// 获取登录用户拥有的系统
export function getUserSystems(param: ApiCommon.SidReqParam) {
  return api.postV2<ApiCommon.GetUserSystemsRespData>({
    url: '/retail/access/control/getUserSystems',
    data: param
  });
}
// 获取系统下的项目
export function getUserProjects(param: ApiCommon.SidReqParam) {
  return api.postV2<ApiCommon.GetUserProjectsRespData>({
    url: '/retail/access/control/getUserProjects',
    data: param
  });
}
