// 商品类目模块路由
import { RouteRecordRaw } from 'vue-router';
import { AppEnv } from '@/utils';

const LayoutView = () => import('@/components/layout/Index.vue');
const login = () => import('@/pages/login/index.vue');
const orgList = () => import('@/pages/orgList/index.vue');
const orgDetail = () => import('@/pages/orgDetail/index.vue');
const postList = () => import('@/pages/postList/index.vue');
const downloadList = () => import('@/pages/download/index.vue');
const testPage = () => import('@/pages/test/index.vue');

const orgManagementModules: RouteRecordRaw[] = [
  {
    path: '/login',
    component: login,
    meta: {
      title: '登录',
      icon: 'content'
    }
  },
  {
    path: '/org',
    component: LayoutView,
    meta: {
      title: '',
      icon: 'content'
    },
    children: [
      {
        path: '/org',
        component: orgList,
        meta: {
          desc: '组织列表页',
          title: '组织管理'
        }
      },
      {
        path: '/org/:id/:type?/:extendId?',
        component: orgDetail,
        meta: {
          desc: '组织列表页 type:[legend,department,employee,related]',
          title: '组织管理'
        }
      }
    ]
  },
  {
    path: '/post',
    component: LayoutView,
    meta: {
      title: '岗位管理',
      icon: 'content'
    },
    children: [
      {
        path: '/post',
        component: postList,
        meta: {
          desc: '岗位列表页',
          title: '岗位管理'
        }
      }
    ]
  },
  {
    path: '/download',
    component: LayoutView,
    meta: {
      title: '下载中心',
      icon: 'content'
    },
    children: [
      {
        path: '/download',
        component: downloadList,
        meta: {
          desc: '下载中心列表',
          title: '下载中心'
        }
      }
    ]
  }
];

if (!AppEnv.isProduction) {
  orgManagementModules[0].children?.push({
    path: '/test',
    component: testPage,
    meta: {
      title: '测试页面'
    }
  });
}
export { orgManagementModules };
