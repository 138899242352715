import { createRouter, createWebHistory } from 'vue-router';
import { projectName } from '@/utils/index';
import { routes } from './routes';

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

/** @ts-ignore */
router.beforeEach((to, from, next) => {
  if (to.meta?.title) {
    /** @ts-ignore */
    document.title = `${projectName ? `${projectName}-` : ''}${to.meta.title}`;
  } else {
    // document.title = `${projectName ? `${projectName}-` : ''}${to.path.slice(1)}`;
    document.title = '组织中心';
  }
  next();
});

export default router;
