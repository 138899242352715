import { defineStore } from 'pinia';
import { getUserEmployeeInfo } from '@/api/organizing';

interface State {
  // 加载状态 0未加载; 1加载中; 2已加载
  loadStatus: number;
  user?: ApiOrg.UserEmployeeInfo;
}

// 登录用户信息相关 store
const useLoginUserDetailStore = defineStore('loginUserDetailStore', {
  state: (): State => ({
    loadStatus: 0,
    user: undefined
  }),
  actions: {
    async tryLoadUserDetail() {
      if (this.loadStatus === 0 && this.loadStatus === 0) {
        await this.loadUserDetail();
      }
    },
    async loadUserDetail() {
      try {
        this.loadStatus = 1;
        const data = await getUserEmployeeInfo();
        console.log('data', data);
        this.user = data.data.employeeInfo;
        this.loadStatus = 2;
      } catch (e: any) {
        console.log(e);
        this.loadStatus = 0;
      }
    }
  }
});

export { useLoginUserDetailStore };
