import { defineStore } from 'pinia';
import { getUserProjectResource, getUserProjects, getUserSystems } from '@/api/common';

interface State {
  sid: number;
  projectId: number;
  // 加载状态 0未加载; 1加载中; 2已加载
  loadStatus: number;
  resources: ApiCommon.AuthorityInfo[];
  systems: ApiCommon.UserSystem[];
  projects: ApiCommon.ProjectInfo[];
}

function canAccessUrl(resources: ApiCommon.AuthorityInfo[], url: string): boolean {
  for (const resource of resources) {
    if (resource.url === url) {
      return true;
    }
    if (canAccessUrl(resource.children, url)) {
      return true;
    }
  }
  return false;
}
// 权限相关 store
const useAuthorityStore = defineStore('authorityStore', {
  state: (): State => ({
    sid: 0,
    projectId: -1,
    loadStatus: 0,
    systems: [],
    projects: [],
    resources: []
  }),
  actions: {
    updateSystemId(sid: number) {
      this.sid = sid;
    },
    updateProjectId(projectId: number) {
      this.projectId = projectId;
      this.loadStatus = 0;
    },
    // 获取登录用户拥有的系统
    async tryLoadUserSystems() {
      if (this.systems.length > 0) {
        return;
      }
      const data = await getUserSystems({ sid: this.sid });
      this.systems = data.data.systems;
    },
    // 获取用户拥有的项目
    async tryLoadUserProjects() {
      const data = await getUserProjects({ sid: this.sid });
      this.projects = data.data.projects;
    },
    async tryLoadUserProjectResource() {
      if (this.loadStatus === 0 && this.projectId > 0) {
        await this.loadUserProjectResource();
      }
    },
    async loadUserProjectResource() {
      try {
        this.loadStatus = 1;
        const data = await getUserProjectResource({
          projectId: this.projectId
        });
        this.resources = data.data.resources;
        this.loadStatus = 2;
      } catch (e: any) {
        console.log(e);
        this.loadStatus = 0;
      }
    },
    // 判断资源能否访问
    accessUrl(url: string): boolean {
      this.tryLoadUserProjectResource().then(null);
      return canAccessUrl(this.resources, url);
    }
  }
});

export { useAuthorityStore };
