import { createApp } from 'vue';

import ElementPlus from 'element-plus';
import { createPinia } from 'pinia';
import 'element-plus/dist/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
// @ts-ignore
import vue3TreeOrg from 'vue3-tree-org';
import 'vue3-tree-org/lib/vue3-tree-org.css';
import '@/style/element-variables.scss';
import './style/global.scss';
import EUI from '@aciga/e-ui-next';
import '@aciga/e-ui-next/dist/theme-chalk/css/index.css';

import { setUploadBaseUrl } from '@njld/oss-helper';
import router from './router';
import App from './App.vue';

setUploadBaseUrl(process.env.VUE_APP_OBS_HOST);
// 一个轻量的store管理工具
// https://pinia.vuejs.org/zh/introduction.html
const pinia = createPinia();

const app = createApp(App);

app.use(ElementPlus, { locale, size: 'small', zIndex: 3000 });
app.use(EUI, undefined);
app.use(vue3TreeOrg);
app.use(pinia);
app.use(router);
app.mount('#app');
