import move_up_disabled_icon from '@/assets/images/move-up-disabled.png';
import move_down_disabled_icon from '@/assets/images/move-down-disabled.png';
import move_del_disabled_icon from '@/assets/images/move-del-disabled.png';

import move_up_hover_icon from '@/assets/images/move-up-hover.png';
import move_down_hover_icon from '@/assets/images/move-down-hover.png';
import move_del_hover_icon from '@/assets/images/move-del-hover.png';

import move_up_select_icon from '@/assets/images/move-up-select.png';
import move_down_select_icon from '@/assets/images/move-down-select.png';
import move_del_select_icon from '@/assets/images/move-del-select.png';

import move_up_active_icon from '@/assets/images/move-up-active.png';
import move_down_active_icon from '@/assets/images/move-down-active.png';
import move_del_active_icon from '@/assets/images/move-del-active.png';

export const allowUploadImageType = ['image/jpeg', 'image/jpg', 'image/png'];

export const MOVE_ICON_MAP = {
  move_up_disabled_icon,
  move_down_disabled_icon,
  move_del_disabled_icon,
  move_up_hover_icon,
  move_down_hover_icon,
  move_del_hover_icon,
  move_up_select_icon,
  move_down_select_icon,
  move_del_select_icon,
  move_up_active_icon,
  move_down_active_icon,
  move_del_active_icon
};
