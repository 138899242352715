import type { UploadRawFile, UploadProps, UploadRequestOptions } from 'element-plus';
import { EMessage } from '@aciga/e-ui-next';
import SparkMD5 from 'spark-md5';
import { ossPreviewLink, getOssToken, uploadFileAPI, uploadOss } from '@/api/common';
import { allowUploadImageType } from '@/config';

interface ImageInfo {
  width: number;
  height: number;
}
export const isDateStr = (date: string) => /\d{4}-\d{2}-\d{2}/.test(date);

/**
 * 把字符用指定字符填充
 * @param h 待格式化内容
 * @param symbol 符号
 * @example
 *  const month = (new Date()).getMonth() // -> 8
 *  const monthFull = stringPad(month, '0') // -> '08'
 */
export const stringPad = (str: string | number, symbol: string = '0', len: number = 2) =>
  (symbol.repeat(len) + str).slice(-len);

/**
 * 判断是否跨年
 * @param range 日期字符串范围 ['2020-12xxx', '2021-12xxx']
 */
export const isYearCross = (range: string[]): boolean => {
  const a = range[0].match(/\d{4}/);
  const b = range[1].match(/\d{4}/);
  if (a !== null && b !== null) {
    return a[0] !== b[0];
  }
  return true;
};

/**
 * 为空则返回空，否则返回其它结果
 * @param a 字段值
 * @param b 待返回值
 * @example
 *  const a = a === '' ? '' : `${a}_test` // before
 *  const a = handleEmptyString(a, `${a}_test`)
 */
export const handleEmptyString = (a: string, b: any) => (a === '' ? '' : b);

/**
 * 千分位数字格式，小数点不格式化
 * https://mrluo.life/article/detail/115
 * @param num  待格式化数值
 * @param symbol 连接符
 * @example
 *  formatNumber('12345.1234') // 12,345.1234
 */
export const formatNumber = (num: string | number, symbol: string = ','): string => {
  if (num === '' || num === undefined || num === null) return '';
  const nums = num.toString().split('.');
  let intPart = nums[0];
  const digitPart = nums[1];
  let ret = '';
  while (intPart.length > 3) {
    ret = `${symbol}${intPart.slice(-3)}${ret}`;
    intPart = intPart.slice(0, intPart.length - 3);
  }
  if (intPart) {
    ret = intPart + ret;
  }

  return ret + (digitPart ? `.${digitPart}` : '');
};
/**
 * 上传文件前检查文件类型
 * @param file
 * @param accept string ".zip"
 */
export const checkFileExt = (file: File, accept: string): boolean => {
  // accept设置为.zip居然可以选择其它文件，所以通过文件后缀验证
  const fileName = file.name;
  const pos = fileName.lastIndexOf('.');
  const lastName = fileName.substring(pos, fileName.length);
  if (accept !== lastName) {
    return false;
  }
  return true;
};

/**
 * 下载 ${fileUrl} 对应的资源
 * 注意：在chrome下，如果资源不是同源的，download属性是无效的
 * @param fileName
 * @param fileUrl
 */
export const downloadLinkFile = (fileName: string, fileUrl: string) => {
  const link = document.createElement('a');
  const extensions = fileUrl.split('.').pop();

  fetch(fileUrl)
    .then((res) => res.blob())
    .then((blob) => {
      // 将链接地址字符内容转变成blob地址
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.${extensions}`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
    });
};
// eslint-disable-next-line import/no-mutable-exports
let projectName = '';

export const setProjectName = (pname: string = '') => {
  projectName = pname;
};

export { projectName };

// 组件传入的是['时间戳', '时间戳'] 需要处理成Date => [Date1, Date2]
export const stringToDate = (times: string[]) => times.map((value) => new Date(+value));

// 生成[start, end] 的有序数组
export const makeRange = (start: number, end: number): number[] => {
  const result: number[] = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

function listToMap<T extends object, K extends keyof T>(list: T[], key: K) {
  return list.reduce((map, acc) => {
    map.set(acc[key], acc);
    return map;
  }, new Map<T[K], T>());
}

export { listToMap };

// element-ui 自定义上传图片
export const httpRequest = (item: UploadRequestOptions) =>
  new Promise((resolve, reject) => {
    // console.log('我走到这一步了吗？');
    const formData = new FormData();
    formData.append('multipartFile', item.file);
    formData.append('fileType', 'image');
    formData.append('tenantId', '1');
    uploadFileAPI(formData)
      .then((res) => {
        resolve({ name: item.file.name, url: res });
      })
      .catch(() => {
        reject();
      });
  });

interface getUploadPrivateRequestOption {
  // 资源的目标目录
  resourceDir: string;
}
// 自定义上传到私有云的接口 element-ui
export const getUploadPrivateRequest =
  (options: getUploadPrivateRequestOption) => async (item: UploadRequestOptions) => {
    const fileType = item.file.type;
    const data = await getOssToken({
      resourceUrl: options.resourceDir,
      fileType
    });
    if (data.code === 0) {
      console.log('data', data, item);
      const md5 = await getFileMd5(item);
      const tempArr = item.file.name.split('.');
      let type = 'png';
      if (tempArr.length > 1) {
        type = tempArr[tempArr.length - 1];
      }
      const key = `${data.data.dir}${md5}.${type}`;
      console.log('md5', md5, fileType, type);
      console.log('key', key);
      const formData = new FormData();
      formData.append('key', key);
      formData.append('policy', data.data.policy);
      formData.append('OSSAccessKeyId', data.data.accessId);
      formData.append('signature', data.data.signature);
      formData.append('success_action_status', '200');
      formData.append('file', item.file);
      await uploadOss({
        url: data.data.host,
        formData
      });
      const linkData = await getOssPreviewLink({ fileKey: key });
      console.info('111', key, linkData.data);
      return Promise.resolve({ name: item.file.name, key, url: linkData.data });
    }
    console.info('222', data);
    return Promise.reject(data.message);
  };

export const getOssPreviewLink = ossPreviewLink;

export function getFileMd5(option: UploadRequestOptions) {
  return new Promise((resolve, reject) => {
    // 分片读取文件
    const fileReader = new FileReader();
    const chunkSize = 1024 * 1024 * 2; // 每个分片的大小 2MB
    const chunks = Math.ceil(option.file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const loadFile = (e: ProgressEvent<FileReader>) => {
      spark.append(e.target?.result as ArrayBuffer);
      currentChunk++;
      if (currentChunk < chunks) {
        // 未读取完继续读取
        loadNext();
      } else {
        // 文件读取完
        const md5 = spark.end(); // 获取文件的md5值
        resolve(md5);
      }
    };
    const loadError = () => {
      // 读取文件失败
      reject(new Error('compute file md5 error'));
    };
    const loadNext = () => {
      fileReader.onload = loadFile;
      fileReader.onerror = loadError;
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= option.file.size ? option.file.size : start + chunkSize;
      const data = option.file.slice(start, end);
      fileReader.readAsArrayBuffer(data);
    };
    loadNext();
  });
}

// 获取图片宽高信息
export const getImageInfo = (file: File) =>
  new Promise<ImageInfo>((resolve, reject) => {
    const imgElement = document.createElement('img');
    imgElement.src = URL.createObjectURL(file);
    imgElement.addEventListener('load', () => {
      resolve({
        width: imgElement.width,
        height: imgElement.height
      });
    });
    imgElement.addEventListener('error', () => {
      reject();
    });
  });

// 检查上传图片
export const checkUploadImage: UploadProps['beforeUpload'] = async (rawFile: UploadRawFile) => {
  if (!allowUploadImageType.includes(rawFile.type)) {
    EMessage({
      type: 'warning',
      message: '上传图片只能是PNG/JPG/JPEG格式'
    });
    return false;
  }
  const isLimit5M = rawFile.size / 1024 / 1024 <= 5;
  if (!isLimit5M) {
    EMessage({
      type: 'warning',
      message: '图片大小不能超过5MB'
    });
    return false;
  }
  return getImageInfo(rawFile).then(
    (imgInfo) =>
      new Promise((resolve, reject) => {
        const { width, height } = imgInfo;
        if (width < 800 || height < 800) {
          EMessage({
            type: 'warning',
            message: '图片尺寸为800*800px以上'
          });
          reject();
        }
        resolve();
      })
  );
};

// 检查上传内容
export const getCheckUpload =
  (checkUploadOption: CheckUploadOption) => (rawFile: UploadRawFile) => {
    checkUploadOption.before?.(rawFile);
    const {
      type,
      allowType = [],
      typeErrorMessage = '类型不匹配',
      sizeLimit = Infinity,
      sizeErrorMessage = '',
      widthLimit = [-Infinity, Infinity],
      heightLimit = [-Infinity, Infinity],
      widthHeightErrorMessage = ''
    } = checkUploadOption;
    if (allowType.length > 0 && !allowType.includes(rawFile.type)) {
      EMessage({
        type: 'error',
        message: typeErrorMessage
      });
      checkUploadOption.completion?.(false);
      return Promise.reject();
    }
    const sizeM = rawFile.size / 1024 / 1024;
    if (sizeM > sizeLimit) {
      EMessage({
        type: 'error',
        message: sizeErrorMessage
      });
      checkUploadOption.completion?.(false);
      return Promise.reject();
    }
    if (type === 'image') {
      return getImageInfo(rawFile).then(
        (imgInfo) =>
          new Promise((resolve, reject) => {
            const { width, height } = imgInfo;
            const widthMin = widthLimit[0];
            const widthMax = widthLimit[1];
            const heightMin = heightLimit[0];
            const heightMax = heightLimit[1];
            console.log('%c [  ]-286', 'font-size:13px; background:pink; color:#bf2c9f;');
            console.log(widthMin, widthMax, width, heightMax, heightMin, height);
            if (
              !(
                width >= widthMin &&
                width <= widthMax &&
                height >= heightMin &&
                height <= heightMax
              )
            ) {
              EMessage({
                type: 'error',
                message: widthHeightErrorMessage
              });
              checkUploadOption.completion?.(false);
              reject();
            }
            checkUploadOption.completion?.(true);
            resolve(true);
          }),
        () => {
          EMessage({
            type: 'error',
            message: '图片解析失败'
          });
          checkUploadOption.completion?.(false);
          return Promise.reject();
        }
      );
    }
    checkUploadOption.completion?.(true);
    return Promise.resolve();
  };

export const hideNumberString = (numStr: string, hide: boolean) => {
  let result = '';
  for (let index = 0; index < numStr.length; index++) {
    if (hide && index >= 3 && index < numStr.length - 4) {
      result += '*';
    } else {
      result += numStr[index];
    }
  }
  return result;
};

export const downLoad = (url: string, fileName?: string) => {
  const eleLink = document.createElement('a');
  eleLink.href = url;
  if (fileName) {
    eleLink.download = fileName;
  }
  eleLink.click();
};

interface FilterParam {
  [key: string]: any;
}
export const filterReqParam = <T extends FilterParam>(param: T): T => {
  const result: FilterParam = {};
  Object.entries(param).reduce((pValue, cValue) => {
    const [key, value] = cValue;
    if (!(value === 0 || value === null || value === undefined || value === '')) {
      pValue[key] = value;
    }
    return pValue;
  }, result);
  return result as T;
};

export const AppEnv = {
  // 判断是否是生产环境
  get isProduction() {
    return process.env.VUE_APP_ENV === 'production';
  },
  // 判断是否是测试环境
  get isTest() {
    return process.env.VUE_APP_ENV === 'staging';
  },
  // 判断是否是开发环境
  get isDevelopment() {
    return process.env.VUE_APP_ENV === 'development';
  },
  // 当前系统ID
  get sid() {
    return this.isProduction ? 9 : 7;
  }
};
